/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */
import "react-big-calendar/lib/css/react-big-calendar.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "animate.css/animate.css";
import "react-datepicker/dist/react-datepicker.css";
import "./src/styles/global.css";

import React from "react";
import { createRoot, hydrateRoot } from "react-dom/client";

// =============================================================================
// React 18 Migration Fix
// =============================================================================
/**
 * Replaces Gatsby's default hydration method to support React 18's createRoot API.
 *
 * Why?
 * - React 18 deprecated ReactDOM.render() in favor of createRoot
 * - Ensures proper concurrent mode features and future compatibility
 * - Fixes warning: "ReactDOM.render is no longer supported"
 *
 * Docs: https://react.dev/reference/react-dom/client/createRoot
 */
export const replaceHydrateFunction = () => (element, container, callback) => {
  const root = createRoot(container);
  root.render(element);

  // const root = hydrateRoot(container, element);
  if (callback) {
    callback();
  }
  return root;
};

// =============================================================================
// Scroll Management
// =============================================================================
/**
 * Initial client-side render handler
 * - Resets scroll position on initial load
 * - Ensures consistent starting point for navigation
 */
export const onInitialClientRender = () => {
  console.log("Initial client render complete");
  window.scrollTo(0, 0); // Reset to top on initial load
};

/**
 * Custom scroll position management
 * - Handles anchor links (#hash navigation)
 * - Improves scroll behavior for client-side navigation
 *
 * Changes:
 * 1. Uses requestAnimationFrame for smoother scroll timing
 * 2. Added null checks for element existence
 * 3. Removed arbitrary timeout in favor of RAF
 * 4. Returns false to prevent Gatsby default scroll handling
 */
export const shouldUpdateScroll = ({ routerProps: { location } }) => {
  // ================================================
  // Safety Check 1: Verify DOM readiness
  // ================================================
  const waitForDOM = () =>
    new Promise((resolve) => {
      if (document.readyState === "complete") return resolve();
      document.addEventListener("DOMContentLoaded", resolve);
    });

  // ================================================
  // Safety Check 2: Element validation
  // ================================================
  const scrollToHash = async () => {
    await waitForDOM();

    if (!location?.hash) return;

    const hash = location.hash.substring(1);
    if (!hash) return;

    const targetElement = document.getElementById(hash);
    if (!targetElement) {
      console.warn(`Hash target #${hash} not found`);
      return;
    }

    // ================================================
    // Smooth Scroll with Boundary Check
    // ================================================
    requestAnimationFrame(() => {
      const elementTop = targetElement.getBoundingClientRect().top;
      const isAlreadyVisible =
        elementTop >= 0 && elementTop <= window.innerHeight;

      if (!isAlreadyVisible) {
        targetElement.scrollIntoView({
          behavior: "smooth",
          block: "start",
          inline: "nearest",
        });
      }
    });
  };

  // ================================================
  // Execution Flow
  // ================================================
  if (location?.hash) {
    scrollToHash().catch(console.error);
  } else {
    window.scrollTo(0, 0);
  }

  // ================================================
  // Critical: Disable Gatsby's default scroll logic
  // ================================================
  return false;
};

// =============================================================================
// Best Practice Notes
// =============================================================================
/**
 * Why not use Gatsby's default scroll handling?
 * - Fine-grained control needed for hash navigation
 * - Default behavior sometimes conflicts with anchor links
 * - Better support for smooth scroll animations
 *
 * Performance Considerations:
 * - requestAnimationFrame ensures scroll timing aligns with browser repaints
 * - Optional chaining (callback?.) prevents null/undefined errors
 * - Clean return false pattern ensures single source of truth
 */
